import React, { useEffect, useState } from 'react';
import { InputField } from 'lincd-input/lib/components/InputField';
import { useTranslate } from '@tolgee/react';
import { Button } from 'lincd-mui-base/lib/components/Button';
import style from './JoinInputButton.scss.json';
import './JoinInputButton.scss';
import { Spinner } from '../atoms/Spinner';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { Dialog } from '@capacitor/dialog';

interface JoinInputButtonProps {
  value?: string;
  description?: string;
  isLoading: boolean;
}
const JoinInputButton = ({
  value,
  description,
  isLoading,
}: JoinInputButtonProps) => {
  let { t } = useTranslate();
  let prefix = 'jointeam';
  const [poetNumber, setPoetNumber] = useState<string>('');
  let [loading, setLoading] = useState(false);
  const user = useAuth().user as Player;
  const navigate = useNavigate();

  const handlePoetNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPoetNumber(inputValue);
  };

  const joinTeam = async () => {
    // check poet number not empty
    if (!poetNumber) {
      alert(t(prefix + '.enterTeamNumber', 'Please enter Peace Team Number'));
      return;
    }

    setLoading(true);

    // check if user is in trial/event mode, they will be removed from the team once they join a number team
    // if user is NOT in either trial/event mode, they can join another team while remaining in previous team
    let isRemovedFromPreviousTeam =
      user.isInCertainMode('event') || user.isInCertainMode('trial');
    console.log(isRemovedFromPreviousTeam, 'isRemovedFromPreviousTeam');

    // check if team exists or not if exists add user to team
    Team.addUserToTeam(
      parseInt(poetNumber),
      user,
      isRemovedFromPreviousTeam,
      true,
    )
      .then(async (result) => {
        //Now that the user has setup the profile AND joined a team then can start the game
        if (result.team) {
          // add user to all action
          Team.addUserToAction(user).then((res) => {
            // set user.profileSetupCompleted to true after addUserToTeam is success
            // to prevent the user from navigating back or manually entering the URL to the home page
            // and that will make a new bug "user does not have a team"
            user.profileSetupCompleted = true;

            // set current team on frontend
            if (user.currentTeam) {
              user.currentTeam = result.team;
            }

            // continue to index page
            setLoading(false);
            navigate(ROUTES.index.path);
          });
        } else if (result.error) {
          setLoading(false);
          await Dialog.alert({
            title: 'Something went wrong',
            message: result.error.message,
          });
        } else {
          setLoading(false);
          await Dialog.alert({
            title: t(prefix + '.teamNotFoundTitle', 'Team not found'),
            message: t(
              prefix + '.teamNotFoundMessage',
              'Team not found. Please enter correct Peace Team Number.',
            ),
          });
        }
      })
      .catch((error) => {
        console.error('Error joining team:', error);
        alert('Team joining failed');
      });
  };

  return (
    <div>
      <InputField
        placeholder={t(prefix + '.teamNumberPlaceholder', 'Peace Team Number')}
        value={poetNumber}
        type="text"
        onChange={handlePoetNumber}
        pattern={'[0-9]*'}
        required={true}
        autoFocus
        aria-label={t(prefix + '.teamNumberInput', 'Peace Team Number input')}
      />
      <Button
        variant={'filled'}
        className={style.buttonJoinTeam}
        color={'primary'}
        onClick={joinTeam}
        fullWidth={true}
        disabled={isLoading}
        aria-label={t(prefix + '.joinTeamButton', 'Join team button')}
      >
        {t(prefix + '.joinPeaceTeam', 'Join Peace Team')}
      </Button>
      <p aria-labelledby={t(prefix + '.noNumberLabel', 'No Team Number Info')}>
        {t(prefix + '.noNumber', description)}
      </p>

      {/* <p aria-labelledby={t(prefix + '.noNumberLabel', 'No Team Number Info')}>
        {t(
          prefix + '.noNumber',
          'If you do not know your Peace Team Number ask the person who invited you for it.',
        )}
      </p> */}

      {/*{<br></br>}*/}
      {/*<p>*/}
      {/*  {t(*/}
      {/*    prefix + '.onlineGameNoNumber',*/}
      {/*    'For those playing one of the online versions of the Peace Game, you will receive an email with instructions to receive your Peace Team Number as a part of the registration process. This is typically done a week before the first meeting.',*/}
      {/*  )}*/}
      {/*</p>*/}
      {isLoading && (
        <Spinner
          aria-label={t(prefix + '.loadingSpinner', 'Loading spinner')}
        />
      )}
      {/*</form>*/}
    </div>
  );
};

export default JoinInputButton;
