import React from 'react';
import './SubMenu.scss';
import * as style from './SubMenu.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Button } from 'lincd-mui-base/lib/components/Button';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import { RemoveAccountButton } from 'lincd-auth/lib/components/RemoveAccountButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { Container } from '../../layout/Container';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useCometChat } from 'lincd-cometchat/lib/hooks/useCometChat';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';
import { SwitchLanguage } from './SwitchLanguage';
import { useTranslate } from '@tolgee/react';

function SubMenu() {
  const navigate = useNavigate();
  const auth = useAuth<Player, UserAccount>();
  const cometChat = useCometChat();
  const { t } = useTranslate();
  let prefix = 'subMenu';

  const onSignOut = async () => {
    cometChat.logout();
    auth.signout();
  };

  return (
    <Container maxWidth={'sm'}>
      <div className={style.menuContainer}>
        <Button
          className={cl(style.buttonContainer, style.left)}
          variant="outlined"
          startIcon={
            <img
              src={asset('/images/icons/PowerButtonIcon.svg')}
              alt={t(prefix + '.logout', 'Logout')}
              width="25px"
            />
          }
          onClick={onSignOut}
          aria-label={t(prefix + '.logout', 'Logout')}
        >
          {t(prefix + '.logout', 'Logout')}
        </Button>
        <Button
          className={cl(style.buttonContainer)}
          variant="outlined"
          startIcon={
            <img
              src={asset('/images/icons/SupportService.svg')}
              alt={t(prefix + '.contact', 'Contact')}
              width="30px"
            />
          }
          onClick={() => navigate(ROUTES.contact.path)}
          aria-label={t(prefix + '.contact', 'Contact')}
        >
          {t(prefix + '.contact', 'Contact')}
        </Button>

        <RemoveAccountButton
          className={cl(style.buttonContainer, style.right)}
          aria-label={t(prefix + '.removeAccount', 'Remove Account')}
        />
      </div>
      <SwitchLanguage />
    </Container>
  );
}

export default SubMenu;
