import React, { useEffect } from 'react';
import { DefaultLayout } from '../layout/DefaultLayout';
import './ResourceCardWithNav.scss';
import style from './ResourceCardWithNav.scss.json';
import { ResourceCard } from 'lincd-irlcg/lib/components/molecules/ResourceCard';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../routes';
import { Resource } from 'lincd-irlcg/lib/shapes/Resource';
import { resource4 } from '../data-references';

const ResourceCardWithNav = (props) => {
  const navigate = useNavigate();
  const resource = props.of as Resource;
  const goToResource = () => {
    navigate(
      generatePath(ROUTES.resource_detail.path, { id: resource.identifier }),
      //ROUTES.resource_detail.path.replace(':id', resource.identifier as string),
    );
  };
  return <ResourceCard onClick={goToResource} {...props} />;
};

export default ResourceCardWithNav;
