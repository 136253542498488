import React, { ChangeEvent, useEffect, useState } from 'react';
import './TeamSelect.scss';
import style from './TeamSelect.scss.json';
import { useTranslate } from '@tolgee/react';
import { Team } from 'lincd-irlcg/lib/shapes/Team';
import { ShapeSet } from 'lincd/lib/collections/ShapeSet';
import { UserAccount } from 'profile-plus/lib/shapes/UserAccount';
import { Player } from 'lincd-irlcg/lib/shapes/Player';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import AlertModal from '../molecules/AlertModal';
import { cl } from 'lincd/lib/utils/ClassNames';
import { LinkedStorage } from 'lincd/lib/utils/LinkedStorage';
import { useLocation } from 'react-router-dom';

interface TeamSelectProps {
  className?: string;
  onTeamChange?: (team: Team) => void;
}

const TeamSelect = ({ className, onTeamChange }: TeamSelectProps) => {
  const { t } = useTranslate();
  let prefix = 'profile';
  const auth = useAuth<Player, UserAccount>();
  const userAccount = auth.userAccount;
  const user = userAccount.accountOf as Player;

  const [userTeams, setUserTeams] = useState<ShapeSet<Team>>(null);
  const [currentTeam, setCurrentTeam] = useState<Team>(user?.currentTeam);
  const [switchTeamModal, setSwitchTeamModal] = useState('');

  const location = useLocation();

  useEffect(() => {
    Team.getUserTeams().then((response) => {
      if (response) {
        setUserTeams(response);
      }
    });
  }, [currentTeam]);

  useEffect(() => {
    if (!currentTeam) {
      setSwitchTeamModal(
        t(
          prefix + '.noTeamModalMessage',
          'You are not currently playing under any team. Please contact support.',
        ),
      );
    }
  }, [currentTeam, auth, t]);

  const changeTeamHandler = (event) => {
    const selectedTeam = Team.getFromURI(event.target.value);
    setCurrentTeam(selectedTeam);
    setSwitchTeamModal(
      t(
        prefix + '.teamChangeMessage',
        `Now you are playing under PEACE TEAM ${selectedTeam.identifier}`,
      ),
    );

    //we can update the current team in the frontend
    //and it will automatically be updated on the backend
    auth.user.currentTeam = selectedTeam;

    // update the current team in the backend
    LinkedStorage.promiseUpdated().then(() => {
      setCurrentTeam(selectedTeam);

      // if the onTeamChange callback is provided, call it to send the updated team to the parent component
      if (onTeamChange) {
        onTeamChange(selectedTeam);
      }
    });
  };

  const switchTeamModalHandler = () => {
    setSwitchTeamModal('');
  };

  // if the user is only in 1 team, don't render the team switch
  if (location.pathname != '/profile' && (!userTeams || userTeams.size < 2)) {
    return null;
  }

  return (
    <div className={style.Root}>
      <select
        name="Currently Playing Under"
        className={cl(style.select, className)}
        onChange={changeTeamHandler}
        aria-label={t(
          prefix + '.currentlyPlayingUnder',
          'Currently Playing Under',
        )}
        value={currentTeam?.uri}
      >
        {userTeams?.map((team) => (
          <option key={team?.uri} value={team?.uri} className={style.option}>
            {t(prefix + '.peaceTeam', 'PEACE TEAM')}{' '}
            {typeof team.identifier != 'string'
              ? +team.identifier
              : team.identifier.toUpperCase()}
          </option>
        ))}
      </select>
      <div className={style.arrow}>
        <svg
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </div>
      <AlertModal
        isOpen={!!switchTeamModal}
        onClose={switchTeamModalHandler}
        onAgree={switchTeamModalHandler}
        confirmationText={switchTeamModal}
        aria-label={t(prefix + '.alertModal', 'Alert Modal')}
      />
    </div>
  );
};

export default TeamSelect;
