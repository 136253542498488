import React from 'react';
import './TextArea.scss';
import style from './TextArea.scss.json';
import { useStyles } from 'lincd/lib/utils/Hooks';

function TextArea(props) {
  props = useStyles(props, style.TextArea);
  return <textarea {...props} />;
}

export default TextArea;
